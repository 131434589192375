<script>
import { Line } from "vue-chartjs";
import EventBus from "@/assets/js/EventBus";
export default {
  extends: Line,
  data() {
    return {
      gradient: null,
      gradient2: null,
      gradient3: null,
    };
  },
  mounted() {
    this.gradient = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);
    this.gradient3 = this.$refs.canvas.getContext("2d").createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");

    this.gradient3.addColorStop(0, "rgba(0, 100, 255, 0.9)");
    this.gradient3.addColorStop(0.5, "rgba(0, 100, 255, 0.25)");
    this.gradient3.addColorStop(1, "rgba(0, 100, 255, 0)");


    EventBus.$on('DataSale', (labels,data1,data2,data3) => {
        this.renderChart(
            {
              labels: labels,
              datasets: [
                {
                  label: "Soles",
                  borderColor: "#FFFFFF",
                  pointBackgroundColor: "white",
                  borderWidth: 1,
                  pointBorderColor: "white",
                  backgroundColor: this.gradient,
                  data: data1
                },
                {
                  label: "Dolares",
                  borderColor: "#FFFFFF",
                  pointBackgroundColor: "white",
                  borderWidth: 1,
                  pointBorderColor: "white",
                  backgroundColor: this.gradient2,
                  data: data2
                },
                {
                  label: "Pesos",
                  borderColor: "#FFFFFF",
                  pointBackgroundColor: "white",
                  borderWidth: 1,
                  pointBorderColor: "white",
                  backgroundColor: this.gradient3,
                  data: data3
                }
                
              ]
            },
            { responsive: true, maintainAspectRatio: false }
          );
    });
    

    
  }
};
</script>
