<template>
  <div>
    <b-modal size="xl" class="modal-size" hide-footer v-model="modal_reservation" title="HABITACIONES OCUPADAS">
     
       <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="15%" class="text-center">Tipo Hab.</th>
                    <th width="5%" class="text-center">Hab.</th>
                    <th width="50%" class="text-center">Cliente</th>
                    <th width="8%" class="text-center">F. Llegada</th>
                    <th width="8%" class="text-center">F. Salida</th>
                    <!-- <th width="8%" class="text-center">F. de Reserva</th> -->
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr >
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-left"> {{ item.type_room_name  }}</td>
                    <td class="text-center"> {{ item.room_number  }}</td>
                    <td class="text-left"> {{ item.client_name + ' - '+item.client_document_number  }}</td>
                    <td class="text-center"> {{ item.start_date  }}</td>
                    <td class="text-center"> {{ item.final_date  }}</td>
                    <!-- <td class="text-right"> {{ item.to  }}</td> -->
            
                  </tr>
                </tbody>
              </table>
            </div>
    </b-modal>


    <LoadingComponent :is-visible="isLoading"/>

  </div>
</template>

<style>


</style>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import EventBus from "@/assets/js/EventBus";
import CodeToName from "@/assets/js/CodeToName";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'
export default {
  name: "ModalClient",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      modal_reservation: false,
      isLoading:false,
      data_table: [],

 
    };
  },
  created (){
 
  },
  mounted () {
    EventBus.$on('ModalRoomControlActiveShow', () => {
      this.isLoading = true;
      this.modal_reservation = true;
      this.ListRoomControlActives();
    });
  },
  methods: {
    ListRoomControlActives,
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    }
  },
};



function ListRoomControlActives() {
      let url = this.url_base + "home/list-room-control-actives";;
      let me = this;
      axios({
          method: "GET",
          url: url,
      }).then(function (response) {
            if (response.data.status == 200) {
            me.data_table = response.data.result;
          } 
      })

      this.isLoading = false;
}

</script>
