<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Inicio</strong>
          </CCardHeader>

          <CCardBody>
              <CRow>
                <CCol sm="3" class="">
                  <b-link class="text-decoration-none"  @click="ModalcheckInnShow">
                  <div class="w-100 border rounded bg-light p-1">
                    <CCallout color="info">
                      <b-row>
                        <b-col md="5 text-center">
                          <img src="@/assets/icons/dashboard/notificacion.png" class="img-fluid" alt="Nuevo">
                        </b-col>
                        <b-col md="7 text-right">
                          <small class="text-muted">Llegadas</small><br>
                          <strong class="h4 pl-1">{{ arrivals }}</strong>
                        </b-col>
                      </b-row>
                    </CCallout>
                  </div>
                  </b-link>
                </CCol>
                <CCol sm="3" class="">
                  <b-link class="text-decoration-none"  @click="ModalCheckOutShow">
                  <div class="w-100 border rounded bg-light p-1">
                    <CCallout color="danger">
                      <b-row>
                        <b-col md="5 text-center">
                          <img src="@/assets/icons/dashboard/salir.png" class="img-fluid" alt="Nuevo">
                        </b-col>
                        <b-col md="7 text-right">
                          <small class="text-muted">Salidas</small><br>
                          <strong class="h4 pl-1">{{ departures }}</strong>
                        </b-col>
                      </b-row>
                    </CCallout>
                  </div>
                  </b-link>
                </CCol>
                <CCol sm="3" class="">
                  <b-link class="text-decoration-none"  @click="ModalRoomControlActiveShow">
                  <div class="w-100 border rounded bg-light p-1">
                    <CCallout color="warning">
                      <b-row>
                        <b-col md="5 text-center">
                          <img src="@/assets/icons/dashboard/cama.png" class="img-fluid" alt="Nuevo">
                        </b-col>
                        <b-col md="7 text-right">
                          <small class="text-muted">Ocupadas</small><br>
                          <strong class="h4 pl-1">{{ occupieds }}</strong>
                        </b-col>
                      </b-row>
                    </CCallout>
                  </div>
                  </b-link>
                </CCol>
                <CCol sm="3" class="">
                  <b-link class="text-decoration-none"  @click="ModalBreakfastsShow">
                  <div class="w-100 border rounded bg-light p-1">
                    <CCallout color="primary">
                      <b-row>
                        <b-col md="5 text-center">
                          <img src="@/assets/icons/dashboard/nuevos.png" class="img-fluid" alt="Nuevo">
                        </b-col>
                        <b-col md="7 text-right">
                          <small class="text-muted">Nro de Desayunos</small><br>
                          <strong class="h4 pl-1">{{ breakfasts }}</strong>
                        </b-col>
                      </b-row>
                    </CCallout>
                  </div>
                  </b-link>
                </CCol>
        

              
              </CRow>
          </CCardBody>
        </CCard>

        <b-row>
          <b-col md="9">
            <CCard>
              <CCardBody>
                <SalesRoom/>
              </CCardBody>
            </CCard>
          </b-col>
          <b-col md="3">
            <CCard>
              <CCardBody>


                <div class="w-100">
              
                    <b-link class="text-decoration-none"  @click="modalObserverVouchersShow">
                    <b-card no-body :class="'text-center ' + (voucher_observer > 0 ? 'bg-danger':'bg-primary')+ ' height-card'">
                      <b-row class="no-gutters h-100 pt-3 pb-3">
                        <b-col md="4" class="text-light align-self-center text-center">
                          <img src="@/assets/icons/facturas.png" class="" />
                        </b-col>
                        <b-col md="4" class="text-light align-self-center text-center">
                          <span> Compr. <br> Obs.</span>
                        </b-col>
                        <b-col md="4" class="text-light align-self-center text-center">
                          <span class="align-middle h3">{{voucher_observer}}</span>
                        </b-col>
                      </b-row>
                    </b-card>
                    </b-link>
      
                </div>
                
                <div class="w-100 mt-1">
                  <b-link class="text-decoration-none" @click="modalCPEShow" >
                  <CWidgetIcon header="CPE" text="Sunat" color="gradient-info">
                    <img src="@/assets/icons/cpe.png" class="" />
                  </CWidgetIcon>
                  </b-link>
                </div>
              </CCardBody>
            </CCard>
          </b-col>
        </b-row>
        
      </CCol>
    </CRow>


    <ModalCPESunat />
    <ModalObserverVouchers />
    <ModalCheckInn/>
    <ModalCheckOut/>
    <ModalRoomControlActive/>
    <ModalBreakfasts/>
  </div>
</template>

<script>
import SalesRoom from './pages/SalesDashboard'
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import ModalCPESunat from './components/ModalCPESunat'
import ModalObserverVouchers from './components/ModalObserverVouchers'
import ModalCheckInn from './components/ModalCheckInn'
import ModalCheckOut from './components/ModalCheckOut'
import ModalRoomControlActive from './components/ModalRoomControlActive'
import ModalBreakfasts from './components/ModalBreakfasts'

export default {
  name: 'Dashboard',
  components: {
   SalesRoom,
   ModalCPESunat,
    ModalObserverVouchers,
    ModalCheckInn,
    ModalCheckOut,
    ModalRoomControlActive,
    ModalBreakfasts,
  },
  data () {
    return {
      arrivals:0,
      departures:0,
      occupieds:0,
      breakfasts:0,
      voucher_observer:0,
    }
  },
  methods: {
    StartInformation,
    modalCPEShow,
    modalObserverVouchersShow,

    ModalcheckInnShow,
    ModalCheckOutShow,
    ModalRoomControlActiveShow,
    ModalBreakfastsShow,
  },
  mounted() {
    this.StartInformation();
   
  },
  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
}
function modalCPEShow() {
  EventBus.$emit('ModalCPESunatShow');
}
function modalObserverVouchersShow() {
  EventBus.$emit('ModalObserverVouchersShow');
}
function ModalcheckInnShow() {
  EventBus.$emit('ModalCheckInnShow');
}

function ModalCheckOutShow() {
  EventBus.$emit('ModalCheckOutShow');
}

function ModalRoomControlActiveShow() {
  EventBus.$emit('ModalRoomControlActiveShow');
}

function ModalBreakfastsShow() {
  EventBus.$emit('ModalBreakfastsShow');
}


function StartInformation() {

  let me = this;
  let url = me.url_base + "home/start-information";

  axios({
    method: "GET",
    url: url,
    headers: {
      token: me.token,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.arrivals = response.data.result.arrivals;
        me.departures = response.data.result.departures;
        me.occupieds = response.data.result.occupieds;
        me.breakfasts = response.data.result.breakfasts;
        me.voucher_observer = response.data.result.sales;
        EventBus.$emit('DataSale',response.data.result.cash_flow.cash_flow_date,response.data.result.cash_flow.total_pen,response.data.result.cash_flow.totol_usd,response.data.result.cash_flow.total_clp);
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurido un error', timer: 2000,})
      }
    })

}
</script>
